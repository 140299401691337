export function pc2phone(idName){
    const element = document.getElementById(idName);
    
    if(!element){
        throw new Error(`未找到id：${idName}`)
    }
    const rect = element.getBoundingClientRect();
    const width = rect.width;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    element.style['transform-origin'] = 'left top'
    element.style['transform'] = `scale(${screenWidth/width})`
}